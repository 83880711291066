body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
    --color-1: #FEFCFB;
    --color-2: #000000;
    --color-3: #001F54;
    --color-4: #034078;
    --color-5: #1282A2;
    --color-6: #66738E;
    --color-7: #FFCB90;
    --color-8: #0A1128;
    --color-9: rgba(0, 197, 255, 0.13);
    --color-10: #356693;
    --red: red;
    --yellow: yellow;

    --background-1: #001F54;
    --background-2: #034078;
    --background-3: #FFE2C5;

    --hover-1: #001F54;
    --activ-1: #001F54;

}

[data-theme="dark"] {
}
