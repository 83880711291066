.admin-line {
    height: 0.1rem;
    background-color: var(--color-3);
}

.btn-admin-panel-section {
    display: flex;
    flex-wrap: wrap;
}

.btn-admin-panel {
    display: inline-block;
    padding: .375rem .75rem;
    border: 0.15rem solid var(--color-3) !important;
    font-size: 1.1rem;
    line-height: 1.5;
    color: var(--color-3) !important;
}

.btn-admin-panel.disabled {
    opacity: 0.1;
    pointer-events: none;
}

.btn-select-list-bg {

}

.btn-select-list-sm {

}

#mass-schedule, #lecturers-schedule,
#psalmists-schedule, #developer,
#edit-articles, #view-photogallery,
#edit-information, #edit-subpages,
#mass-schedule-section {
    display: none;
}

#admin-buttons {
    display: flex;
}

.el-list-section {
    width: 70vw;
    height: 35vh;

    min-width: 750px;
    min-height: 250px;
}

.el-list-section-bg {
    width: 70vw;
    height: 90vh;

    min-width: 750px;
    min-height: 250px;
}

.left-section {
    width: 55vw;
    min-width: 750px;
}

.el-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50vw;
    height: 5vh;
    min-width: 450px;
    min-height: 45px;
    border: 0.1rem solid var(--color-3);
    color: var(--color-3);
    border-radius: .5rem;
}

.admin-icon-btn {
    width: 3vw;
    height: 3vw;
    min-width: 40px;
    min-height: 40px;
    border-radius: .25rem !important;
    box-shadow: 0px 0px !important;
    border: 0px !important;
    background-color: var(--color-1) !important;
}

.admin-icon-btn:hover {
    box-shadow: 0 -1px 0 #0000000a, 0 2px 4px #00000040 !important;
    background-color: var(--color-7) !important;
}

.admin-icon-btn:active {
    border: 0.15rem solid var(--color-7) !important;
    background-color: var(--color-7) !important;
}

.admin-icon-btn-shadow {
    box-shadow: 0 -1px 0 #0000000a, 0 2px 3px #00000040 !important;
}

.list-and-btn-section {

}

/*-------- EDIT INFORMATION -----------*/
.parish-info-section {
    width: 50vw;
    /*min-width: ;*/
    justify-content: space-between;
    align-items: center;
}

.admin-cover-photo-img {
    width: 35%;
    max-height: 100%;
}

.admin-cover-photo-frame {
    width: 70vw;
    height: 15vh;
    min-height: 150px;
    background-color: var(--color-1);
    box-shadow: 0 -0.1rem 0 #0000000a, 0 0.2rem 0.2rem #00000040 !important;
}

.admin-input-text {
    width: 30vw;
    /*min-width: 220px;*/
    border-color: var(--color-3)
}

.admin-input-text-short {
    width: 20vw;
    /*min-width: 220px;*/
    border-color: var(--color-3)
}

.admin-input-time {
    width: 8vw;
    text-align: center;
    /*min-width: 220px;*/
    border-color: var(--color-3)
}

.admin-input-check {
    border-color: var(--color-3)
}

.text-editor {
    width: 80vw;
}

/*neskor premiestnit cele .css do tohto*/
@media (min-width: 1070px) {
    .btn-admin-panel {
        width: 17vw;
        /*height: 5.5vw;*/
    }
}