ul.main-menu {
    display: inline-flex;
    list-style: none;
    padding: 0;
    float: right;
    z-index: 7;
}

li {
    list-style-type: none;
}

.menu-item a {
    font-size: 1.1rem;
    font-weight: 900;
    line-height: 2.5rem;
    position: relative;
    display: block;
    color: var(--color-3);
    margin: 27px 17px;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
}

.navbar-title {
    width: 28vw;
    min-width: 300px;
    display: inline-block;
    float: left;
}

.navbar-buttons {
    display: inline-block;
    float: left;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
}

.sub__menus {
    position: absolute;
    display: none;
    background: white;
    min-width: 350px;
    z-index: 1000;
    box-shadow: 0 10px 12px rgba(22, 33, 39, 0.25);
}

.sub__menus__arrows {
    position: relative;
}

.sub__menus__arrows svg {
    position: absolute;
    top: 13px;
}

.menu-item .sub__menus a {
    margin: 0.1rem;
    font-weight: 600;
}

@media (min-width: 1070px) {
    .sub__menus__arrows:hover .sub__menus {
        display: block;
    }

    .sub__menus__arrows__full:hover .sub__menus {
        display: block;
        z-index: 1000000;
    }

    .menu-item:hover > a, .menu-item .sub__menus li:hover a {
        text-decoration-line: underline;
    }

    .menu-item:active > a, .menu-item .sub__menus li:active a {
        color: var(--color-4) !important;
    }
}

@media (max-width: 1069px) {
    .sub__menus {
        width: 80vw;
        min-width: 320px;
    }

    .navbar-title {
        width: 80vw;
    }

    .main-nav .menu-item:hover > a {
        text-decoration-line: underline;
    }

    .main-nav .menu-item:active > a {
        background-color: var(--color-7);
    }

    .main-nav .menubar__button {
        display: block !important;
        position: relative;
        float: right;
        border-radius: 5px;
        cursor: pointer;
        z-index: 10037;
        border: 0.1rem solid var(--color-1);
    }

    .main-nav .menubar__button:hover {
        border: 0.1rem solid var(--color-3);
    }

    .main-nav ul.menu-overlay {
        display: block !important;
        position: absolute;
        z-index: 10000;
        background-color: var(--color-1);
        left: 0;
        right: 0;
    }

    .main-nav .menu-item a {
        text-align: center;
        color: var(--color-3);
    }

    .menu-item .sub__menus li a {
        text-align: start;
    }

    .main-nav ul.main-menu {
        display: none
    }

    .main-nav .sub__menus__arrows:hover .sub__menus {
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .sub__menus__Active {
        display: block !important;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
}
