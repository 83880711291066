.navbar_title {
    display: flex;
    flex-direction: column;
}

/* ----- SLIDE IMAGES -----*/
.slide-img-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.img-overlay {
    position: absolute;
    height: 36vh;
    width: 16vw;
    margin-top: 2vh;
    margin-left: 4vw;
}

.img-overlay > img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
}

.title-overlay {
    position: absolute;
    text-align: center;
}

.overlay-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(57, 57, 57, 0.7);
}

/* -------- DESKTOP LAYOUTS ------- */
@media (min-width: 1070px) {
    .navbar_title {
        min-height: 180px;
    }

    .header-fade-container {
        height: 60vh;
        width: 100vw;

        min-height: 450px;
    }

    .header-img-container {
        height: 15vh;
        width: 100vw;
        min-height: 120px;
    }

    .overlay-box {
        width: 75%;
    }
}

/* -------- PHONE LAYOUTS ------- */
@media (max-width: 1070px) {
    #phone-not-shown {
        display: none;
    }

    .header-fade-container {
        height: 40vw;
        /*width: 100vw;*/

        /*min-height: 100px;*/
    }

    .header-img-container {
        height: 17vh;
        width: 100vw;
        min-height: 160px;
    }

    .overlay-box {
        width: 100%;
    }
}