.input-feast {
    min-width: 250px;
    width: 35vw;
}

.input-place {
    min-width: 150px;
    width: 10vw;
}

.input-time {
    min-width: 140px;
    width: 8vw;
}

.input-intention {
    min-width: 220px;
    width: 20vw;
}

.input-confession {
    min-width: 80px;
    width: 5vw;
}

/* ------------------------- AnnouncementsTable ----------------*/
.an-color {
    background-color: rgba(255, 166, 0, 0.11);
}

.an-border {
    border: 0.1rem solid var(--color-3);
}

.an-tbl-day, .p-an-tbl-day {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #ffae00;
}

.an-tbl-row, .p-an-tbl-row, .p-an-tbl-row-sm {
    display: flex;
    flex-direction: row;
}

.an-tbl-col {
    display: flex;
    flex-direction: column;
}


.an-tbl-header-day, .p-an-tbl-header-day {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.an-tbl-header-row, .p-an-tbl-header-row {
    display: flex;
    flex-direction: row;
}

.an-tbl-mass, .p-an-tbl-mass {
    display: flex;
    flex-direction: column;
}

.an-tbl-1, .an-tbl-1-split {
    display: flex;
    align-items: center;
    justify-content: center;
}

.an-tbl-2, .p-an-tbl-2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.an-tbl-3, .p-an-tbl-3 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.an-tbl-4, .p-an-tbl-4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fa-font-day, .fa-font-date, .p-fa-font-day, .p-fa-font-date {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-align: center;
    color: var(--color-1);
}

.fa-font-feast, .p-fa-font-feast {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-style: italic;
    text-align: center;
    color: var(--color-2);
}

.fa-font-place, .p-fa-font-place {
    text-align: center;
    color: var(--color-3);
}

.fa-font-time, .p-fa-font-time {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    text-align: center;
    color: var(--color-3);
}

.fa-font-intention, .p-fa-font-intention {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-align: left;
    color: var(--color-2);
}

.fa-font-confession, .p-fa-font-confession {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-style: italic;
    text-align: right;
    color: var(--color-3);
}

.fa-font-an-header, .p-fa-font-an-header {
    text-align: center;
    color: var(--color-1);
}

.an-border-thick {
    border: 0.1rem solid var(--color-3);
}

/* -------- DESKTOP LAYOUTS ------- */
@media (min-width: 1070px) {
    .an-tbl-day {
        width: 13%;
        min-height: 10vh;
    }

    .an-tbl-row {
        width: 100%;
        min-height: 11vh;
    }

    .an-tbl-header-day {
        width: 13%;
        min-height: 6vh;
    }

    .an-tbl-header-row {
        width: 100%;
        min-height: 6vh;
    }

    .an-tbl-mass {
        width: 90%;
    }

    .an-tbl-1-split {
        width: 40%;
    }

    .an-tbl-1 {
        width: 40%;
    }

    .an-tbl-2 {
        width: 25%;
    }

    .an-tbl-3 {
        width: 20%;
    }

    .an-tbl-4 {
        width: 60%;
    }

    .an-tbl-col {
        width: 100%;
    }

    .fa-font-day {
        font-size: 1.4rem;
    }

    .fa-font-date {
        font-size: 1rem;
    }

    .fa-font-feast {
        font-size: 1.1rem;
    }

    .fa-font-place {
        font-size: 1.4rem;
    }

    .fa-font-time {
        font-family: 'Lato', sans-serif;
        font-weight: 900;
        font-size: 1.8rem;
    }

    .fa-font-intention {
        font-size: 1.2rem;
    }

    .fa-font-confession {
        font-size: 1.2rem;
    }

    .fa-font-an-header {
        font-size: 1.3rem;
    }
}

/*-------- PHONE LAYOUTS ------- */
@media (max-width: 1070px) {
    .an-border-thick {
        border: 1px solid var(--color-3);
    }

    .an-tbl-day {
        width: 13%;
        min-height: 6vh;
    }

    .an-tbl-row {
        width: 100%;
        min-height: 6vh;
    }

    .an-tbl-header-day {
        width: 13%;
        height: 5vh;
        min-height: 30px;
    }

    .an-tbl-header-row {
        width: 100%;
        height: 5vh;
        min-height: 30px;
    }

    .an-tbl-mass {
        width: 87%;
    }

    .an-tbl-1-split {
        width: 40%;
    }

    .an-tbl-1 {
        width: 40%;
    }

    .an-tbl-2 {
        width: 27%;
    }

    .an-tbl-3 {
        width: 20%;
    }

    .an-tbl-4 {
        width: 60%;
    }

    .an-tbl-col {
        width: 100%;
    }

    .fa-font-day {
        font-size: 2.2vw;;
    }

    .fa-font-date {
        font-size: 2vw;
    }

    .fa-font-feast {
        font-size: 1.9vw;
    }

    .fa-font-place {
        font-size: 2vw;
    }

    .fa-font-time {
        font-size: 3vw;
    }

    .fa-font-intention {
        padding-left: 0rem !important;
        font-size: 2.4vw;
    }

    .fa-font-confession {
        font-size: 2.1vw;
    }

    .fa-font-an-header {
        font-size: 2.4vw;;
    }
}
