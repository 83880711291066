@media print {
    .p-an-table, .p-week-text {
        max-width: 800px;
    }
}

@media (max-width: 1070px) {
    .p-week-text {
        width: 95vw;
    }
}

@media (min-width: 1070px) {
    .p-an-table, .p-week-text {
        min-width: 800px;
        width: 40vw;
    }
}

.p-week-text-print {
    width: 100%;
}

.p-an-table {
    width: 100%;
}

.p-an-tbl-day {
    width: 13%;
    min-height: 50px;
}

.p-an-tbl-row-sm {
    width: 100%;
    min-height: 30px;
}

.p-an-tbl-row {
    width: 100%;
    min-height: 60px;
}

.p-an-tbl-header-day {
    width: 13%;
    min-height: 50px;
}

.p-an-tbl-header-row {
    width: 100%;
    min-height: 50px;
}

.p-fa-font-day {
    font-size: 20px;
}

.p-fa-font-date {
    font-size: 14px;
}

.p-fa-font-feast {
    padding: 0.5rem !important;
    font-size: 14px;
}

.p-fa-font-place {
    font-size: 17px;
}

.p-fa-font-time {
    font-size: 24px;
}

.p-fa-font-intention {
    font-size: 17px
}

.p-fa-font-confession {
    font-size: 17px;
}

.p-fa-font-an-header {
    font-size: 16px;
}