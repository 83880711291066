body {
    background-color: var(--color-1) !important;
}

html {
    font-size: 100%;
}

.color-1 {
    color: var(--color-1);
}

.color-2 {
    color: var(--color-2);
}

.color-3 {
    color: var(--color-3);
}

.color-6 {
    color: var(--color-6);
}

.color-8 {
    color: var(--color-8);
}

.color-9 {
    color: var(--color-9);
}

.background-color-1 {
    background-color: var(--color-1)
}

.background-color-3 {
    background-color: var(--color-3)
}

.background-color-5 {
    background-color: var(--color-5)
}

.background-color-6 {
    background-color: var(--color-6)
}

.background-color-7 {
    background-color: var(--color-7)
}

.background-color-10 {
    background-color: var(--color-10)
}

.background-color-an-1 {
    background-color: #c77b00;
}


.font-900 {
    font-family: 'Raleway', sans-serif;
    font-weight: 900;
}

.font-900-lato {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
}

.font-800 {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
}

.font-700 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
}

.font-600 {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
}

.font-400-lato {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

.font-400 {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
}

.font-300 {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
}

.fa-flex-col {
    display: flex;
    flex-direction: column;
}

.fa-flex-row {
    display: flex;
    flex-direction: row;
}

.fa-text-justify {
    text-align: justify;
}

.fa-text-right {
    text-align: right;
}

.fa-pointer {
    cursor: pointer;
}

.fa-input-border {
    border: 0.1rem solid var(--color-6) !important;
}

.fa-form-label {
    font-size: 1.2rem;
}

.disable-page-box {
    background-color: var(--color-7);
}

/* -------------------------------------------------- */
/* INTRODUCTION
/* -------------------------------------------------- */
.actuality-item {
    /*height: 15.5vh;*/
    /*min-height: 130px;*/
    cursor: pointer;
    border-bottom: 0.1vh solid var(--color-1);
}

.actuality-item:hover {
    background-color: var(--color-4);
}

.actuality-item:active {
    background-color: var(--color-5);
}

.actuality-line {
    height: 0.1vh;
    background-color: var(--color-1);
}

.article-line {
    height: 0.1vh;
    background-color: var(--color-6);
}

.fa-pg-title {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-6);
}

.contacts-tab {
    background-color: var(--color-6);
}

.actuality-tab {
    background-color: var(--color-3);
}

.btn-primary {
    min-width: 150px;
    min-height: 40px;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    border: 0.1rem solid transparent !important;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem !important;
    color: var(--color-1) !important;
    box-shadow: 0 -1px 0 #0000000a, 0 1px 1px #00000040 !important;
    background-color: var(--color-3) !important;
}

.btn-primary:hover, .btn-with-icon:hover {
    box-shadow: 0 -1px 0 #0000000a, 0 2px 4px #00000040 !important;
    background-color: var(--color-4) !important;
}

.btn-primary:active, .btn-with-icon:active {
    background-color: var(--color-5) !important;
}

.btn-secondary {
    border-radius: .25rem !important;
    box-shadow: 0 -1px 0 #0000000a, 0 1px 1px #00000040 !important;
    background-color: var(--color-1) !important;
}

.btn-secondary:hover {
    box-shadow: 0 -1px 0 #0000000a, 0 2px 4px #00000040 !important;
    background-color: var(--color-7) !important;
}

.btn-secondary:active {
    border: 0.15rem solid var(--color-7) !important;
    background-color: var(--color-7) !important;
}

.btn-with-icon {
    min-width: 200px;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.5;
    border-radius: .25rem !important;
    color: var(--color-1) !important;
    box-shadow: 0 -1px 0 #0000000a, 0 1px 1px #00000040 !important;
}

.btn-border {
    border: 0.1rem solid transparent !important;
}

.btn-text {
    text-decoration: underline;
    cursor: pointer;
}

.btn-text:hover {
    font-weight: 600;
}


.custom-dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: .5rem;
    background-color: var(--color-1);
    border: 0.1rem solid var(--color-3);
}

.modal-pdf {
    min-width: 90vw;
    /*min-width: 1070px;*/
}

.modal-users {
    min-width: 60vw;
    /*min-width: 800px;*/
}

.modal-text-editor {
    min-width: 90vw;
    /*min-width: 1000px;*/
}

/* -------- DESKTOP LAYOUTS ------- */
@media (min-width: 1070px) {
    .desktop-not-shown {
        display: none;
    }

    .desktop-row-phone-col {
        display: flex;
        flex-direction: row;
    }

    .app-container {
        height: 100vh;
        min-height: 650px;
        align-items: center;
        justify-content: space-between;
        background-color: var(--color-1);
    }

    .window-content-container {
        width: 80vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: var(--color-1);
    }

    .window-content-container-full {
        width: 95vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: var(--color-1);
    }

    /* -------------------------------------------------- */
    /* INTRODUCTION ------------------------------------- */
    /* -------------------------------------------------- */
    .contacts-tab {
        width: 25vw;
        min-width: 340px;
    }

    .actuality-tab {
        width: 55vw;
        min-height: 400px;
    }

    .announcements-tab {
        width: 60vw;
        min-width: 850px;
    }

    .modal-finance {
        width: 80vw;
        min-width: 500px;
    }

    .contact-row-description {
        align-items: center;
        justify-content: end;
    }

    .contact-row-content {
        align-items: center;
        justify-content: start;
    }

    .contact-row-content > div > h5 {
        text-align: start;
    }
}

/*-------- PHONE LAYOUTS ------- */
@media (max-width: 1070px) {
    .phone-not-shown {
        display: none !important;
    }

    .desktop-row-phone-col {
        display: flex;
        flex-direction: column;
    }

    .app-container {
        min-height: 100vh;
        align-items: center;
        justify-content: space-between;
        background-color: var(--color-1);
    }

    .window-content-container {
        min-width: 350px;
        width: 95vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        background-color: var(--color-1);
    }

    /* -------------------------------------------------- */
    /* INTRODUCTION ------------------------------------- */
    /* -------------------------------------------------- */
    .contacts-tab {
        min-width: 340px;
    }

    .actuality-tab {
        min-width: 340px;
    }

    .announcements-tab {
        width: 95vw;
        min-width: 350px;
    }

    .modal-finance {
        width: 90vw;
        min-width: 340px;
    }

    .contact-row-description {
        align-items: center;
        justify-content: center;
    }

    .contact-row-content {
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }

    .contact-row-content > div > h5 {
        text-align: center;
    }

}

.scroll {
    overflow: auto;
    float: left;
}

.scroll1::-webkit-scrollbar {
    width: 0.3rem;
}

.scroll1::-webkit-scrollbar-thumb {
    background: var(--color-3);
}

.scroll2::-webkit-scrollbar {
    width: 0.2rem;
}

.scroll2::-webkit-scrollbar-thumb {
    background: var(--color-3);
}

body::-webkit-scrollbar {
    display: none;
}

.scroll-to-top-btn {
    background-color: var(--color-3) !important;
}

/* -------- LOADER -------- */
.loader-container-app {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: var(--color-1);
}

.loader-app {
    border: 16px solid var(--color-3);
    border-top: 16px solid var(--color-1);
    border-radius: 50%;
    width: 130px;
    height: 130px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* -------- loader -------- */
