.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--color-3);
}

.footer-qr-code {
    display: flex;
    align-items: center;
    height: 100%;
}

.footer-hours-table {
    border-bottom: 0.1rem solid var(--color-1);
}

.footer-buttons {
    display: flex;
    flex-direction: row;
}

.footer-linklist-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.footer-btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--color-3);
    border: 0.1rem solid var(--color-1) !important;

}

.footer-btn-link:hover {
    border: 0.2rem solid var(--color-1) !important;

}

/* -------- DESKTOP LAYOUTS ------- */
@media (min-width: 1070px) {
    #desktop-not-shown {
        display: none;
    }

    .footer-container {
        /*min-height: 270px;*/
        min-width: 1070px;
    }

    .footer-qr-code {
        max-width: 4.3vw;
    }

    .bank-account-section {
        min-width: 290px;
    }

    .office-hours-section {
        min-width: 300px;
    }

    .footer-linklist-section {
        justify-content: flex-end;
    }

    .footer-btn-link {
        width: 7vw;
        height: 4vw;
    }

    .modal-login {
        width: 25vw;
    }

    .footer-counter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 1069px) {
    .footer-qr-code {
        width: 17vw;
        max-width: 70px;
    }

    .footer-container {
        min-width: 360px;
    }

    .footer-btn-link {
        width: 35vw;
        height: 18vw;
        min-width: 130px;
        min-height: 65px;
        max-width: 130px;
        max-height: 70px;
    }

    .footer-linklist-section {
        justify-content: space-around;
    }

    .modal-login {
        min-width: 350px;
    }

    .footer-counter {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}