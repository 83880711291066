.photoGallery-item {
    cursor: pointer;
    overflow: hidden;
    border: 0.2rem solid var(--color-1);
    box-shadow: 0 -0.1rem 0 #0000000a, 0 0.2rem 0.2rem #00000040 !important;
}

.photoGallery-item:hover {
    box-shadow: 0 -0.1rem 0 #0000000a, 0 0.4rem 0.4rem #00000040 !important;
}

.photoGallery-item:active {
    box-shadow: 0 -1px 0 #0000000a, 0 1px 1px #00000040 !important;
    border: 0.2rem solid var(--color-3)
}

.photoGallery-img-container {
    width: 100%;
    height: 60%;
}

.photoGallery-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.photoGallery-content-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 96%;
    height: 40%;
}

.photoGallery-line {
    height: 0.3vh;
    background-color: var(--color-6);
}

.photoGallery-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

a {
    text-decoration: none;
}

.photogallery-title {
    font-size: 1.1rem;
}

/* -------- DESKTOP LAYOUTS ------- */
@media (min-width: 1070px) {
    .photoGallery-item {
        height: 35vh;
        width: 17vw;
        min-height: 250px;
        min-width: 250px;
    }
}

/* -------- PHONE LAYOUTS ------- */
@media (max-width: 1070px) {
    .photoGallery-item {
        max-height: 350px;
        max-width: 350px;
    }
}