.article-item {
    cursor: pointer;
    overflow: hidden;
    border: 0.2rem solid var(--color-1);
    box-shadow: 0 -0.1rem 0 #0000000a, 0 0.2rem 0.2rem #00000040 !important;
}

.article-item:hover {
    box-shadow: 0 -0.1rem 0 #0000000a, 0 0.4rem 0.4rem #00000040 !important;
}

.article-item:active {
    box-shadow: 0 -1px 0 #0000000a, 0 1px 1px #00000040 !important;
    border: 0.2rem solid var(--color-3)
}

.article-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* -------- DESKTOP LAYOUTS ------- */
@media (min-width: 1070px) {
    .article-item {
        height: 30vh;
        min-height: 220px;
    }

    .article-img-container {
        width: 25%;
    }

    .article-content-container {
        margin-left: 1rem;
        width: 75%;
    }
}

/* -------- PHONE LAYOUTS ------- */
@media (max-width: 1069px) {
    .article-img-container {
        margin-bottom: 0.5rem;
        width: 100%;
    }

    .article-content-container {
        width: 100%;
    }

}