.fa-icon-btn-sm {
    width: 1.2vw;
    height: 1.2vw;
    min-width: 15px;
    min-height: 15px
}

.fa-icon-btn-md {
    width: 1.3vw;
    height: 1.3vw;
    min-width: 20px;
    min-height: 20px;
}

.fa-icon-btn-bg {
    width: 1.5vw;
    height: 1.5vw;
    min-width: 25px;
    min-height: 25px;
}

.fa-icon-small {
    width: 1.7vw;
    height: 1.7vw;
    min-width: 30px;
    min-height: 30px;
}

.fa-icon-medium {
    width: 2vw;
    height: 2vw;
    min-width: 30px;
    min-height: 30px;
}

.fa-icon-big {
    width: 3.5vw;
    height: 3.5vw;
    min-width: 30px;
    min-height: 30px;
}

.fa-icon-menu {
    width: 3vw;
    height: 3vw;
    min-width: 30px;
    min-height: 30px;
}

.fa-icon-menu-down {
    width: 1vw;
    height: 1vw;
    min-width: 15px;
    min-height: 15px;
}

.fa-footer-list-icon {
    max-width: 100%;
    height: auto;
}